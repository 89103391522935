import React, {useEffect} from 'react';
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import SEO from '../components/App/SEO';
import { graphql } from 'gatsby';

const androidCreditsPage = ({data}) => {
  // const iframeSrc = window.location.origin + '/auth/login?x-frames-allow-from=' + window.location.origin + (parsed.token ? '&token=' + parsed.token : '');
  const iframeSrc =
    typeof window !== "undefined"
      ? "https://ab.bluemail.me" +
        "?x-frames-allow-from=" +
        window.location.origin
      : "";

  useEffect(() => {
    const interval = setInterval(() => {
      const iframe =
        typeof document !== "undefined" &&
        document.getElementById("your-iframe-id");
      iframe.contentWindow.postMessage({ action: "checkLogin" }, "*");
    }, 1000);

    const handleMessage = (event) => {
      console.log(event);
      const iframe =
        typeof document !== "undefined" &&
        document.getElementById("your-iframe-id");
      if (iframe && event.source === iframe.contentWindow) {
        try {
          const data = JSON.parse(event.data); // Attempt to parse the incoming message as JSON
          if (data.newSrc) {
            // Check if the parsed object has the `newSrc` property
            iframe.src =
              data.newSrc +
              "?x-frames-allow-from=" +
              window.location.origin +
              "&t=" +
              new Date().getTime(); // Update the iframe src to the newSrc value from the message
            window.removeEventListener("message", handleMessage); // Stop listening for messages
          }
        } catch (error) {
          console.error("Failed to parse message data as JSON:", error);
        }
      }
    };

    const handleCheckLogin = (event) => {
      // if (event.origin === "https://iframe-origin.com") {
      try {
        if (event.data.isLoggedIn === true) {
          const iframe =
            typeof document !== "undefined" &&
            document.getElementById("your-iframe-id");
          iframe.src = iframeSrc + "&t=" + new Date().getTime();
          clearInterval(interval);
        }
      } catch (error) {
        console.error("Failed to parse message data as JSON:", error);
      }
      // }
    };

    window.addEventListener("message", handleCheckLogin);

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
      window.removeEventListener("message", handleCheckLogin);
      clearInterval(interval);
    };
  }, []);

  return (
    <Layout env={data.site.siteMetadata.env}>
    <Navbar />
    <SEO 
        postTitle='Android Credits | BlueMail App' 
        postDescription='Everything new about Blue Mail - Redefining Your Email'
        postImage='/img/OG/og_image-Android.png'
        postURL='p1'
        postSEO
    />
    <div className="android-credits-area container pt-120 pb-100">
        <iframe id={'your-iframe-id'} src={iframeSrc} width="800px" height="600px"></iframe>
    </div>
    <Footer />
    </Layout>
)};

export default androidCreditsPage;

export const query = graphql`
query AndroidCreditsPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`